<template>
  <div class="">
    <!-- <center-title title="联系人基本信息" :border="false"></center-title> -->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="162px" class="ruleForm">
      <el-form-item label="采购商简称" prop="company_abbreviation">
        <el-input v-model="ruleForm.company_abbreviation" placeholder="采购商简称"></el-input>
      </el-form-item>
      <el-form-item label="采购商名称" prop="company_name">
        <el-input v-model="ruleForm.company_name" placeholder="采购商名称"></el-input>
      </el-form-item>
      <el-form-item label="采购商类型" prop="company_type">
        <el-select v-model="ruleForm.company_type" placeholder="请选择采购商类型">
          <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系人" prop="contact">
        <el-input v-model="ruleForm.contact" placeholder="联系人"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobile">
        <el-input v-model="ruleForm.mobile" placeholder="手机号码"></el-input>
      </el-form-item>
      <el-form-item label="固定号码" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="固定号码"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="ruleForm.email" placeholder="邮箱"></el-input>
      </el-form-item>
      <el-form-item label="所在地区" class="region" prop="region">
        <!-- <el-form-item prop="provinces">
          <el-select v-model="ruleForm.provinces" placeholder="省份">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="city">
          <el-select v-model="ruleForm.city" placeholder="城市">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="county">
          <el-select v-model="ruleForm.county" placeholder="县区">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-cascader size="large" :options="options" v-model="ruleForm.region" style="width: 100%"
          placeholder="省份/城市/县区" @change="setRegion"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input v-model="ruleForm.address" placeholder="详细地址"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="white md bg-primary" @click="submitForm('ruleForm')">
          下一步
        </el-button>
        <el-button class="reset bg-center white md" @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import CenterTitle from "../../../components/CenterTitle";
import { vxRule } from "@/utils/validator";
// import { regionData } from "element-china-area-data";
export default {
  name: "",
  props: {
    companyInfo: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {
        company_abbreviation: "", //简称
        company_name: "", //名称
        company_type: "", //类型
        contact: "", //联系人
        mobile: "", //手机号码
        phone: "", //固定号码
        email: "", //邮箱
        province_id: "", //省份
        city_id: "", //城市
        area_id: "", //县区
        address: "", //详细地址
      },
      options: [],
      rules: {
        company_abbreviation: vxRule(true, null, "blur", "请输入采购商简称"),
        company_name: vxRule(true, null, "blur", "请输入采购商名称"),
        company_type: vxRule(true, null, "change", "请选择采购商类型"),
        contact: vxRule(true, null, "blur", "请输入联系人"),
        mobile: vxRule(true, "Mobile", "blur", "请输入手机号码"),
        phone: vxRule(true, null, "blur", "请输入固定号码"),
        email: vxRule(true, "Email", "blur", "请输入邮箱"),
        region: vxRule(true, null, "change", "请选择省/市/区/街道"),
        // provinces: vxRule(true, null, "change", "请选择省份"),
        // city: vxRule(true, null, "change", "请选择城市"),
        // county: vxRule(true, null, "change", "请选择县区"),
        address: vxRule(true, null, "blur", "请输入详细地址"),
      },
      value: [],
      typeList: [
        {
          label: "企业",
          value: 0,
        },
        {
          label: "事业单位",
          value: 1,
        },
      ],
    };
  },
  computed: {},
  watch: {
    companyInfo: {
      handler(val) {
        for (const key in this.ruleForm) {
          this.ruleForm[key] = val[key];
        }
        this.$set(this.ruleForm, 'region', [val["province_id"], val["city_id"], val["area_id"]])
      },
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitEmit", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    setRegion(val) {
      this.ruleForm.province_id = val[0];
      this.ruleForm.city_id = val[1];
      this.ruleForm.area_id = val[2];
    },
    getAllAdressList(){
      this.$api.getAllAdressListApi().then(res=>{
        this.options = res.data
      })
    }
  },
  created() {
    this.getAllAdressList()
  },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.el-select {
  width: 100%;
}
</style>